.portal-sidenavigation {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.portal-sidenavigation__link {
  padding: 1em 0 1em 20px;
  cursor: pointer;
  height: auto;
  width: 100%;
  justify-content: left;
  font-size: var(--font-size-4);
  color: var(--font-color-contrast-high);
  font-weight: 600;
  text-transform: uppercase;
  background-color: rgba(0,0,0,0);
  border: 0.2rem solid rgba(0,0,0,0);
}
.portal-sidenavigation__link:focus {
  border: 0.2rem solid var(--color-focus);
  outline: 0;
}
.portal-sidenavigation__user-row {
  padding-left: var(--layout-spacing-unit);
  display: flex;
  height: 6rem;
  font-weight: var(--font-weight-bold);
}
.portal-sidenavigation__user-icon {
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  margin: 1rem;
  border: 1px solid var(--font-color-contrast-high);
  width: 4rem;
  height: 4rem;
  margin: 1rem 1rem 1rem 0;
  padding-left: 0 !important;
}
.portal-sidenavigation__user-icon svg {
  fill: currentColor;
  height: 4rem;
  width: 4rem;
  border-radius: var(--border-radius-circles);
  margin: 0;
}
.portal-sidenavigation__user-text-content {
  margin: auto 0;
  padding: 0 var(--layout-spacing-unit);
  height: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: calc(1rem + var(--layout-spacing-unit)) 0;
  box-sizing: border-box;
}
.portal-sidenavigation--username {
  font-weight: bold;
}
.portal-sidenavigation__logout-link {
  cursor: pointer;
  background-color: rgba(0,0,0,0);
  color: var(--font-color-contrast-high);
  font-size: var(--font-size-4);
  border-bottom: 0.2rem solid rgba(0,0,0,0);
  font-weight: normal;
  width: min-content;
}
.portal-sidenavigation__logout-link:focus {
  text-decoration: underline;
  outline: 0;
}
.portal-sidenavigation__logout-link:focus span {
  text-decoration: none;
}
.portal-sidenavigation__login-header {
  border-bottom: 4px solid var(--bgc-content-body);
}
.portal-sidenavigation__menu {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.portal-sidenavigation__menu-item {
  margin-left: 0;
}
.portal-sidenavigation__menu-item--show {
  display: block;
}
.portal-sidenavigation__menu-item--hide {
  display: none;
}
.portal-sidenavigation__menu-subItem {
  margin-left: 0;
}
.portal-sidenavigation__menu-subItem--parent {
  text-transform: uppercase;
  padding-left: 4rem;
}
.portal-sidenavigation__edit-mode {
  border-radius: unset;
  border: 0.2rem solid var(--bgc-content-container);
  border-top-color: var(--bgc-content-body);
}
.portal-sidenavigation__edit-mode span {
  margin: 0.2rem;
}
.portal-sidenavigation__edit-mode:focus span {
  margin: 0;
}
.portal-sidenavigation__fade-left-right,
.portal-sidenavigation__fade-right-left {
  animation-duration: 0.4s;
}
.portal-sidenavigation__fade-right-left {
  animation-name: fadeOutRight;
}
.portal-sidenavigation__fade-left-right {
  animation-name: fadeInLeft;
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-moz-keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-o-keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*# sourceMappingURL=src/components/navigation/SideNavigation.css.map */