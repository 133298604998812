.portal-search__input {
  width: 100%;
  border: 0.1rem solid transparent;
  border-radius: var(--border-radius-interactable);
  background-color: var(--bgc-inputfield-on-body);
  padding: var(--layout-spacing-unit) !important;
  box-sizing: border-box;
  margin-bottom: 0;
}
.portal-search__input:focus {
  border-color: var(--color-focus);
  outline: none;
}
.portal-search__wrapper {
  background-color: rgba(0,0,0,0);
  bottom: unset;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(22rem);
}
/*# sourceMappingURL=src/components/search/PortalSearch.css.map */