
.flyout-wrapper
  width: 100%
  max-width: 23rem
  position: fixed
  top:  calc(var(--portal-header-height) + var(--layout-spacing-unit))
  bottom: env(safe-area-inset-bottom, 0)
  right: 0
  z-index: 100
  background-color: var(--bgc-content-container)
  transform: translate3d(110%, 0, 0)
  transition: transform cubic-bezier(0, 0, 0.2, 1) 0.5s

  &--isVisible
    transform: translate3d(0, 0, 0) scale(1, 1)
    transition: transform cubic-bezier(0, 0, 0.2, 1) 0.5s
