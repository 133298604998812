
.header-tab
  outline: 0
  cursor: pointer
  display: flex
  align-items: center
  z-index: 1
  background-color: transparent
  transition: background-color var(--portal-transition-duration)
  flex-basis: auto
  flex-grow: 1
  max-width: 15rem
  border: 0.2rem solid rgba(0,0,0,0)

  &__wrapper
    display: flex
    position: relative
    align-items: center
    height: 100%

    &:hover
      background-color: var(--portal-tab-background)

  &__logo-wrapper
    background-color: var(--bgc-apptile-default)
    border-radius: var(--border-radius-apptile)
    height: calc(var(--portal-header-height) * var(--portal-header-icon-scale))
    width: @height
    display: flex
    align-items: center
    justify-content: center
    margin: 0 var(--layout-spacing-unit-small)

  &__logo
    width: 80%
    max-height: 80%
    vertical-align: middle
    border: 0

  &__title
    flex: 1 1 auto
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    min-width: 2.5rem

  &__close-button
    position: relative
    z-index: 10

  &__clickable
    &:before
      content: ''
      width: 100%
      height: 100%
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      border: 0.2rem solid rgba(0,0,0,0)
      box-sizing: border-box;
      z-index: -1

    &:focus:before
      border-color: var(--color-focus)

  &--active
    &:after
      content: ''
      width: 100%
      height: 100%
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      border: 0.2rem solid rgba(0,0,0,0)
      box-sizing: border-box;
      z-index: -1
      background-color: var(--portal-tab-background)
