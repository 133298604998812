
$userRow = 6rem
.portal-sidenavigation
  height: 100%
  display: flex
  flex-direction: column

  &__link
    padding: 1em 0 1em 20px
    cursor: pointer
    height: auto
    width: 100%
    justify-content: left
    font-size: var(--font-size-4)
    color: var(--font-color-contrast-high)
    font-weight: 600
    text-transform: uppercase
    background-color: rgba(0,0,0,0)
    border: 0.2rem solid rgba(0,0,0,0)
    &:focus
      border: 0.2rem solid var(--color-focus);
      outline: 0
  &__user-row
    padding-left: var(--layout-spacing-unit)
    display: flex
    height: $userRow
    font-weight: var(--font-weight-bold)
  &__user-icon
    position: relative
    overflow: hidden;
    border-radius: 100%
    margin: 1rem
    border: 1px solid var(--font-color-contrast-high)
    width: 4rem
    height: @width
    margin: 1rem 1rem 1rem 0
    padding-left: 0 !important; // remove this line, when weird server caching is fixed

    svg
      fill: currentColor
      height: 4rem
      width: @height
      border-radius: var(--border-radius-circles)
      margin: 0

  &__user-text-content
    margin: auto 0
    padding: 0 var(--layout-spacing-unit)
    height: 100%;
    align-items: flex-start
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: calc(1rem + var(--layout-spacing-unit)) 0
    box-sizing: border-box

  &--username
    font-weight: bold

  &__logout-link
    cursor: pointer
    background-color: rgba(0,0,0,0)
    color: var(--font-color-contrast-high)
    font-size: var(--font-size-4)
    border-bottom: 0.2rem solid rgba(0,0,0,0);
    font-weight: normal
    width: min-content

    &:focus
      text-decoration: underline
      outline: 0

    &:focus span
      text-decoration: none

  &__login-header
    border-bottom: 4px solid var(--bgc-content-body)

  &__menu
    flex: 1 1 auto
    overflow-y: auto
    overflow-x: hidden

  &__menu-item
    margin-left: 0

    &--show
      display: block

    &--hide
      display: none

  &__menu-subItem
    margin-left: 0
    &--parent
      text-transform: uppercase;
      padding-left: 4rem;

  &__edit-mode
    border-radius: unset
    border: 0.2rem solid var(--bgc-content-container)
    border-top-color: var(--bgc-content-body)

    span
      margin: 0.2rem

    &:focus span
      margin: 0

  &__fade-left-right,
  &__fade-right-left
    animation-duration: .4s;

  &__fade-right-left
    animation-name: fadeOutRight

  &__fade-left-right
    animation-name: fadeInLeft

// keyframes
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
