
.portal-icon
  font-size: inherit
  height: var(--button-icon-size)
  width: var(--button-icon-size)
  stroke: currentColor
  stroke-width: 2
  stroke-linecap: round
  stroke-linejoin: round
  color: var(--font-color-contrast-high)
  fill: none
  transition: color var(--portal-transition-duration)
