.choose-tab {
  display: flex;
  align-items: center;
  position: relative;
  margin: 2px 0;
  border-radius: var(--border-radius-interactable);
  width: 20rem;
}
@media only screen and (max-width: 748px) {
  .choose-tab {
    max-width: 100%;
  }
}
.choose-tab:first-of-type {
  margin-top: 0.2rem;
}
.choose-tab--active {
  background-color: var(--bgc-apptile-default);
}
.choose-tab--active .choose-tab__logo-wrapper {
  background-color: none;
}
.choose-tab__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  padding: var(--layout-spacing-unit);
  padding-left: 0;
  width: 100%;
}
.choose-tab__button:focus {
  outline: 0;
}
.choose-tab__button:focus:before {
  border-color: var(--color-focus);
}
.choose-tab__button:before {
  content: '';
  width: auto;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  border: 2px solid transparent;
}
.choose-tab__img {
  width: 80%;
  max-height: 80%;
  vertical-align: middle;
  border: 0;
}
.choose-tab__logo-wrapper {
  background-color: var(--bgc-apptile-default);
  border-radius: var(--border-radius-apptile);
  height: calc(var(--portal-header-height) * var(--portal-header-icon-scale));
  width: calc(var(--portal-header-height) * var(--portal-header-icon-scale));
  min-width: calc(var(--portal-header-height) * var(--portal-header-icon-scale));
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 var(--layout-spacing-unit-small) 0 0;
}
.choose-tab .icon-button {
  margin-left: var(--layout-spacing-unit);
}
/*# sourceMappingURL=src/components/ChooseTabs.css.map */