
.edit-mode-side-navigation
  &__headline
    padding: 0 calc(2 * var(--layout-spacing-unit))
    margin-bottom: 0
  &__form
    height: auto
    overflow: auto
    padding: calc(2 * var(--layout-spacing-unit))

    &--unfocusable
      overflow: hidden

    input
      width: 18rem
      &[type=checkbox]
        margin-left: 0

    .image-upload:first-child label
      margin-top: 0
  &__save-button
    margin-top: calc(2 * var(--layout-spacing-unit))
