
.form-label
  display: flex
  align-items: center
  padding-bottom: 0.2rem;

  &__button
    padding: unset
    background-color: unset
    border-radius: 100rem
    width: 1.5rem
    height: 1.5rem
    border-width: 0.1rem
    margin-left:0.2rem

  &__portal-icon
    color: var(--font-color-contrast-middle);
    width: 0.8rem
    margin-left: 0
    margin-right: 0 !important

