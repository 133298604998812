
.multi-select
  &__select
    padding: 0 var(--layout-spacing-unit)
    background-color: var(--bgc-inputfield-on-container)
    border: 0.1rem solid var(--bgc-inputfield-on-container)
    border-radius: var(--border-radius-interactable)
    height: calc(5 * var(--inputfield-size))
    overflow: auto

    label
      margin-top: var(--layout-spacing-unit) !important
      display: flex

      input
        flex-shrink: 0

      span
        overflow: hidden
        text-overflow: ellipsis

  &__footer
    margin: var(--layout-spacing-unit) 0
    display: flex
    button + button
      margin-left: var(--layout-spacing-unit)
