
.password-box
  position: relative

  &__icon {
    position: absolute
    right: 0
    top: 50%
    transform: translateY(-50%)
  }
