
body.body--has-selfservice
  overflow: hidden

.modal-wrapper--selfservice
  padding: calc(4 * var(--layout-spacing-unit)) 0
  overflow: auto
  box-sizing: border-box
  &.modal-wrapper--isVisible
    // z-index: $zindex-4 TODO notifications are also $zindex-4
    z-index: 399

.dialog--selfservice
  margin: auto
  box-sizing: border-box
  min-width: s('min(calc(var(--inputfield-width) + calc(12 * var(--layout-spacing-unit))), 90%)')
  min-height: s('min(200px, 90%)')
  max-height: unset

  input,
  select,
  form
    width: 100%

  form main
    max-height: unset
    padding: 0
