.input-error-message {
  display: flex;
  align-items: center;
  color: var(--font-color-error);
  margin-bottom: var(--layout-spacing-unit);
}
.input-error-message__icon {
  flex: 0 0 auto;
  margin-right: var(--layout-spacing-unit-small);
  color: var(--font-color-error);
}
/*# sourceMappingURL=src/components/forms/InputErrorMessage.css.map */