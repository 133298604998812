@import "./_ucs-vars.styl"
@import "./_variables.styl"

:root
  // portal
  --portal-header-height: var(--layout-height-header)
  --border-radius-apptile: 12.5% // 20px
  --border-radius-apptile-in-folder: 12.5% // 5px
  --border-radius-apptile-in-tab: 12.5% // 2px
  --border-radius-portal-folder: 10%
  --portal-transition-duration: 250ms
  --app-tile-side-length: 8rem
  --portal-header-height: var(--layout-height-header)
  --portal-folder-tile-width: 33%
  --portal-folder-padding: calc(4 * var(--layout-spacing-unit))
  --portal-tab-background: var(--bgc-apptile-default)
  --portal-header-icon-scale: 0.8
  --inputfield-width: 20rem

@media $mqSmartphone
  :root
    --portal-folder-tile-width: 50%
    --portal-folder-padding: calc(2 * var(--layout-spacing-unit))

    --app-tile-side-length: 5.5rem
