
.portal-title
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px
  border: 0.2rem solid rgba(0,0,0,0)

  &:focus
    border: 0.2rem solid var(--color-focus)
    outline: 0

  &__image
    height: 100%
    width: calc(var(--portal-header-height) * var(--portal-header-icon-scale))

  &__portal-home-icon
    display: none
    @media $mqSmartphone
      display: flex
      align-content: center

      svg
        width: calc(3* var(--layout-spacing-unit))
        height: @width

  &__portal-name
    font-size: var(--font-size-2);
    white-space: nowrap
    padding-left: var(--layout-spacing-unit)
