
.image-upload
  &__canvas
    position: relative
    height: 10rem
    width: 10rem
    cursor: pointer
    display: flex
    background: var(--bgc-checkerboard)
    img
      max-height: 10rem
      margin: auto
      max-width: 100%
  &__footer
    margin: var(--layout-spacing-unit) 0
    display: flex
    button + button
      margin-left: var(--layout-spacing-unit)
  &__file-input
    visibility: hidden
    position: absolute
  &__nofile
    height: 100%
    width: 100%
    display: flex
    background-color: var(--bgc-inputfield-on-container)
    span
      margin: auto
  &__maxFileSize
    color: var(--font-color-contrast-middle)
    font-size: var(--font-size-5)
    padding-top: var(--layout-spacing-unit-small)

.image-upload__standby
  position: absolute
  top: 0
  left: 0
  background: var(--bgc-inputfield-on-container)

.loading-enter-active,
.loading-leave-active {
  transition: opacity 0.5s ease;
}

.loading-enter-from,
.loading-leave-to {
  opacity: 0;
}
