
.choose-tab
  display: flex
  align-items: center
  position:relative
  margin: 2px 0
  border-radius: var(--border-radius-interactable)
  width: 20rem

  @media $mqSmartphone
    max-width: 100%

  &:first-of-type
    margin-top: 0.2rem

  &--active
    background-color: var(--bgc-apptile-default)

    & ^[0]__logo-wrapper
      background-color: none

  &__button
    display: flex
    align-items: center
    cursor: pointer
    border: 2px solid transparent
    padding: var(--layout-spacing-unit)
    padding-left: 0
    width: 100%

    &:focus
      outline: 0

      &:before
        border-color: var(--color-focus)

    &:before
      content: ''
      width: auto
      height: 100%
      position: absolute
      left: 0
      right: 0
      border: 2px solid transparent

  &__img
    width: 80%
    max-height: 80%
    vertical-align: middle
    border: 0

  &__logo-wrapper
    background-color: var(--bgc-apptile-default)
    border-radius: var(--border-radius-apptile)
    height: calc(var(--portal-header-height) * var(--portal-header-icon-scale))
    width: @height
    min-width: @height
    display: flex
    align-items: center
    justify-content: center
    margin: 0 var(--layout-spacing-unit-small) 0 0

  .icon-button
    margin-left: var(--layout-spacing-unit)
