.cookie-banner {
  overflow-y: auto !important;
}
.cookie-banner a {
  color: inherit;
  transition: color var(--portal-transition-duration), text-decoration-thickness var(--portal-transition-duration);
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
.cookie-banner a:focus {
  color: var(--color-accent);
  text-decoration-thickness: 3px;
}
.cookie-banner-modal {
  display: flex;
  flex-direction: column;
}
/*# sourceMappingURL=src/components/globals/CookieBanner.css.map */