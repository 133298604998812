
.notifications
  position: fixed
  z-index: $zindex-4
  top: calc(var(--layout-height-header) + 1rem)
  right: var(--layout-spacing-unit)
  width: 90vw
  max-width: 300px
  max-height: 100%
  overflow-y: auto
  padding-right: calc(3 * var(--layout-spacing-unit))

  @media $mqSmartphone
    font-size: var(--font-size-5)
    width: 73vw
    padding-right: 0

  &--in-bar
    @media $mqSmartphone
      right: 0
      left: calc(3 * var(--layout-spacing-unit))
      font-size: var(--font-size-5)

  &--floating
    @media $mqSmartphone
      right: var(--layout-spacing-unit)

  &__close-all
    display: flex
    justify-content: flex-start
    margin-bottom: calc(4 * var(--layout-spacing-unit))

  &__no-notifications
    font-size: var(--font-size-2)

  .flyout-wrapper &
    top: calc(4 * var(--layout-spacing-unit));
    bottom: 0
