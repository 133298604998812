.multi-input__row--multiline {
  --local-stripeColor: var(--bgc-inputfield-on-container);
  padding-top: var(--layout-spacing-unit-small);
  padding-left: var(--layout-spacing-unit);
  margin-left: 2px;
  box-shadow: inset 2px 0 var(--local-stripeColor);
}
.multi-input__row label {
  margin-top: 0;
}
.multi-input__row--singleline {
  display: flex;
  align-items: flex-start;
  gap: var(--layout-spacing-unit);
  margin-bottom: calc(1 * var(--layout-spacing-unit));
}
.multi-input__row--singleline label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.multi-input__row--singleline .icon-button {
  flex: 0 0 auto;
}
.multi-input__row--singleline .multi-input__row__elem {
  flex: 1 1 auto;
}
.multi-input__row--multiline {
  display: flex;
  flex-direction: column;
  margin-bottom: calc(2 * var(--layout-spacing-unit));
}
.multi-input__row--multiline .multi-input__row__elem {
  margin-bottom: var(--layout-spacing-unit);
}
.multi-input__row--invalid {
  --local-stripeColor: var(--font-color-error);
}
.multi-input__row__elem .form-element {
  margin-top: 0;
}
/*# sourceMappingURL=src/components/widgets/MultiInput.css.map */