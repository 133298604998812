.multi-select__select {
  padding: 0 var(--layout-spacing-unit);
  background-color: var(--bgc-inputfield-on-container);
  border: 0.1rem solid var(--bgc-inputfield-on-container);
  border-radius: var(--border-radius-interactable);
  height: calc(5 * var(--inputfield-size));
  overflow: auto;
}
.multi-select__select label {
  margin-top: var(--layout-spacing-unit) !important;
  display: flex;
}
.multi-select__select label input {
  flex-shrink: 0;
}
.multi-select__select label span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-select__footer {
  margin: var(--layout-spacing-unit) 0;
  display: flex;
}
.multi-select__footer button + button {
  margin-left: var(--layout-spacing-unit);
}
/*# sourceMappingURL=src/components/widgets/MultiSelect.css.map */