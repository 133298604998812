
.portal-iframe
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__iframe
    position: relative;
    border: none;
    width: 100%;
    height: 100%;

