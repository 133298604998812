:root {
  --cursor-disabled: not-allowed;
  --border-radius-interactable: 0.25rem;
  --border-radius-container: 0.5rem;
  --border-radius-notification: var(--border-radius-container);
  --border-radius-tooltip: 0.25rem;
  --border-radius-circles: 50%;
  --layout-spacing-unit: 0.5rem;
  --layout-spacing-unit-small: calc(var(--layout-spacing-unit) / 2);
  --layout-height-header: 3rem;
  --layout-height-header-separator: var(--layout-spacing-unit-small);
  --button-size: 36px;
  --button-icon-size: 16px;
  --button-font-size: var(--font-size-4);
  --button-font-size-mobile: var(--font-size-5);
  --button-border-radius: var(--border-radius-interactable);
  --button-icon-border-radius: var(--border-radius-circles);
  --button-bgc-transition: background 250ms;
  --inputfield-size: 2.75rem;
  --select-arrow: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABkSURBVHgB7Y3BCQAhDAS3BEtICVeCJdi5JVwpGsGHiGLECD4ysL9lBjCMpwk8En6p/kV4XuL9WAeo/sr/gwDHi4JAK47YYBXoxQ6bzALH4lnAa4lHgaQpHgVUxW0g4ILYMC6TAZ0BJA3bxN3RAAAAAElFTkSuQmCC");
}
:root {
  --portal-header-height: var(--layout-height-header);
  --border-radius-apptile: 12.5%;
  --border-radius-apptile-in-folder: 12.5%;
  --border-radius-apptile-in-tab: 12.5%;
  --border-radius-portal-folder: 10%;
  --portal-transition-duration: 250ms;
  --app-tile-side-length: 8rem;
  --portal-header-height: var(--layout-height-header);
  --portal-folder-tile-width: 33%;
  --portal-folder-padding: calc(4 * var(--layout-spacing-unit));
  --portal-tab-background: var(--bgc-apptile-default);
  --portal-header-icon-scale: 0.8;
  --inputfield-width: 20rem;
}
@media only screen and (max-width: 748px) {
  :root {
    --portal-folder-tile-width: 50%;
    --portal-folder-padding: calc(2 * var(--layout-spacing-unit));
    --app-tile-side-length: 5.5rem;
  }
}
:root {
/* used to accent some elements: checkbox/radiobox, checked toggle buttons/checked input fields */
  --color-accent: #7ab51d;
  --bgc-content-body: #333331;
  --bgc-content-header: var(--bgc-content-container);
  --bgc-content-container: #1e1e1d;
  --bgc-inputfield-on-container: var(--bgc-content-body);
  --bgc-inputfield-on-body: var(--bgc-content-container);
  --bgc-checkbox-hover: rgba(255,255,255,0.06);
  --bgc-checkbox-focus: rgba(255,255,255,0.12);
  --bgc-loading-circle: var(--font-color-contrast-middle);
  --bgc-user-menu-item-hover: rgba(255,255,255,0.1);
  --bgc-user-menu-item-active: rgba(255,255,255,0.2);
  --bgc-header-number-circle: #bdbdbb;
  --bgc-tab-separator: #5f5f5c;
  --bgc-popup: #5f5f5c;
  --bgc-popup-item-hover: rgba(0,0,0,0.15);
  --bgc-popup-item-active: rgba(0,0,0,0.3);
  --bgc-popup-item-selected: rgba(0,0,0,0.3);
  --bgc-grid-row-hover: rgba(255,255,255,0.04);
  --bgc-grid-row-selected: var(--bgc-grid-row-hover);
  --bgc-tree-row-hover: var(--bgc-grid-row-hover);
  --bgc-tree-row-selected: rgba(255,255,255,0.15);
  --bgc-apptile-default: #868681;
  --bgc-appcenter-app-hover: rgba(255,255,255,0.08);
  --bgc-appcenter-app-active: rgba(255,255,255,0.12);
  --bgc-progressbar-empty: #bdbdbb;
  --bgc-progressbar-progress: var(--bgc-success);
  --bgc-titlepane-hover: rgba(255,255,255,0.04);
  --bgc-underlay: rgba(51, 51, 49, 0.8);
  --bgc-checkerboard: repeating-conic-gradient(var(--bgc-inputfield-on-container) 0% 25%, transparent 0% 50%) 50%/20px 20px;
  --bgc-error: #e4100e;
  --bgc-warning: #975607;
  --bgc-success: #4a6d12;
  --font-size-1: 1.5rem;
  --font-size-2: 1.25rem;
  --font-size-3: 1rem;
  --font-size-4: 0.875rem;
  --font-size-5: 0.75rem;
  --font-size-html: 1rem;
  --font-size-body: var(--font-size-4);
  --font-lineheight-normal: 1.5;
  --font-lineheight-compact: 1.25;
  --font-lineheight-header: 1.3;
  --font-weight-bold: 600;
  --font-color-contrast-high: #fff;
  --font-color-contrast-middle: #bdbdbb;
  --font-color-contrast-low: #868681;
  --font-color-error: #f0758c;
  --font-color-warning: #ff8c00;
  --font-color-success: #9cd147;
  --button-primary-bgc: #5e881d;
  --button-primary-bgc-hover: #567d1b;
  --button-primary-bgc-active: rgba(79,114,24,1);
  --button-primary-bgc-disabled: #5f5f5c;
  --button-bgc: #5f5f5c;
  --button-bgc-hover: #575755;
  --button-bgc-active: rgba(80,80,77,1);
  --button-bgc-disabled: #5f5f5c;
  --button-text-bgc: transparent;
  --button-text-bgc-hover: var(--button-bgc-hover);
  --button-text-bgc-active: var(--button-bgc-active);
  --button-text-bgc-disabled: transparent;
  --button-icon-bgc: transparent;
  --button-icon-bgc-hover: var(--button-bgc-hover);
  --button-icon-bgc-active: var(--button-bgc-active);
  --button-icon-bgc-disabled: transparent;
  --button-icon-highlighted-bgc: var(--bgc-inputfield-on-body);
  --button-icon-highlighted-bgc-hover: var(--button-bgc-hover);
  --button-icon-highlighted-bgc-active: var(--button-bgc-active);
  --button-icon-highlighted-bgc-disabled: transparent;
  --color-focus: var(--font-color-contrast-high);
  --popup-border: 1px solid #bdbdbb;
  --box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  --serveroverview-tile-hover-bgc: #00acb6;
  --serveroverview-tile-hover-color: #1e1e1d;
  --select-arrow: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABnSURBVHgB7Y2xCcAwDAQ1QkbwCBnBI3hzj+BRPhZxJVCkEAVc6OC7544oSfYFQJsrzm/hv+fL54qbYQWWeKz/SQ75MdetgBB38mIFpJj/9AYt8Fn8EKghYiWAMLESiBOLQAsXJ8n/XIbF5kq3Q5vcAAAAAElFTkSuQmCC");
}
html {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-html);
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  line-height: var(--font-lineheight-normal);
  font-size: var(--font-size-body);
  color: var(--font-color-contrast-high);
  margin-top: var(--portal-header-height);
  background-color: var(--bgc-content-body);
  box-sizing: border-box;
}
button {
  padding: 0 calc(2 * var(--layout-spacing-unit));
  height: var(--button-size);
  color: var(--font-color-contrast-high);
  font-size: var(--button-font-size);
  text-transform: uppercase;
  border: 0.2rem solid transparent;
  border-radius: var(--button-border-radius);
  background-color: var(--button-bgc);
  transition: opacity var(--portal-transition-duration);
  cursor: pointer;
  transition: background-color var(--portal-transition-duration);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 748px) {
  button {
    padding: 0 calc(1 * var(--layout-spacing-unit));
    font-size: var(--button-font-size-mobile);
  }
}
button svg:not(:only-child) {
  margin-right: var(--layout-spacing-unit-small);
}
button[disabled] {
  background-color: var(--button-bgc-disabled);
  color: var(--font-color-contrast-middle);
  cursor: var(--cursor-disabled);
}
button[disabled] svg {
  color: var(--font-color-contrast-middle);
}
button:focus {
  border-color: var(--color-focus);
  outline: 0;
}
button.primary {
  background-color: var(--button-primary-bgc);
}
h2 {
  font-size: var(--font-size-2);
  line-height: var(--font-lineheight-normal);
  font-weight: 600;
}
input,
select,
textarea {
  box-sizing: border-box;
  width: var(--inputfield-width);
  height: var(--inputfield-size);
  display: block;
  padding: 0 calc(3 * var(--layout-spacing-unit-small)) !important;
  margin-bottom: var(--layout-spacing-unit);
  font-size: var(--font-size-3);
  color: var(--font-color-contrast-high);
  background-color: var(--bgc-inputfield-on-container);
  border: 0.1rem solid var(--bgc-inputfield-on-container);
  border-radius: var(--border-radius-interactable);
  transition: background-color, color, border;
  transition-duration: var(--portal-transition-duration);
}
@media only screen and (max-width: 748px) {
  input,
  select,
  textarea {
    width: 100%;
    font-size: var(--font-size-4);
    padding: 0 calc(2 * var(--layout-spacing-unit-small)) !important;
  }
}
input:hover,
select:hover,
textarea:hover,
input:focus,
select:focus,
textarea:focus {
  border-color: var(--font-color-contrast-low);
  outline-style: none;
  box-shadow: none;
}
input[invalid],
select[invalid],
textarea[invalid],
input[aria-invalid=true],
select[aria-invalid=true],
textarea[aria-invalid=true] {
  border-color: var(--font-color-error);
}
input[disabled],
select[disabled],
textarea[disabled] {
  cursor: var(--cursor-disabled);
  color: var(--font-color-contrast-low);
}
input[type="radio"],
select[type="radio"],
textarea[type="radio"] {
  height: 1rem;
  width: 1rem;
  margin-right: var(--layout-spacing-unit);
  appearance: none;
  border-radius: 100%;
  padding: 0 !important;
  display: flex;
}
input[type="radio"]:focus,
select[type="radio"]:focus,
textarea[type="radio"]:focus,
input[type="radio"]:focus:checked,
select[type="radio"]:focus:checked,
textarea[type="radio"]:focus:checked {
  border-color: var(--color-focus);
}
input[type="radio"]:checked,
select[type="radio"]:checked,
textarea[type="radio"]:checked {
  border-color: var(--color-accent);
}
input[type="radio"]:checked:after,
select[type="radio"]:checked:after,
textarea[type="radio"]:checked:after {
  display: block;
  content: '';
  border-radius: 100%;
  height: 0.6rem;
  width: 0.6rem;
  top: 5px;
  left: 5px;
  margin: auto;
  background: var(--color-accent);
}
fieldset {
  border: 0;
  padding: 0;
}
select {
  cursor: pointer;
  appearance: none;
  position: relative;
  background-image: var(--select-arrow);
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: 98% center;
}
legend,
label,
div.label {
  display: block;
  margin-top: calc(3 * var(--layout-spacing-unit));
  color: var(--font-color-contrast-middle);
  font-size: var(--font-size-3);
  position: relative;
}
@media only screen and (max-width: 748px) {
  legend,
  label,
  div.label {
    margin-top: calc(1 * var(--layout-spacing-unit));
    font-size: var(--font-size-4);
  }
}
input[type=checkbox] {
  background-color: var(--bgc-inputfield-on-container);
  display: inline-block;
  height: var(--font-size-3);
  width: var(--font-size-3);
  padding: 0 !important;
  margin: 4px;
  margin-right: var(--layout-spacing-unit);
  margin-left: 0;
  border: 2px solid var(--font-color-contrast-low);
  border-radius: 2px;
  background-color: var(--bgc-inputfield-on-container);
  cursor: pointer;
  appearance: none;
}
input[type=checkbox]:focus,
input[type=checkbox]:focus:checked {
  border-color: var(--color-focus);
}
input[type=checkbox]:checked {
  border-color: var(--color-accent);
}
input[type=checkbox] + span {
  vertical-align: top;
  cursor: pointer;
}
input[type=checkbox]:checked:after {
  content: "✓";
  display: block;
  width: 100%;
  height: 100%;
  line-height: 111%;
  font-size: 0.7rem;
  font-weight: bold;
  text-align: center;
  color: var(--color-accent);
}
textarea {
  max-width: 100%;
  padding-top: calc(3 * var(--layout-spacing-unit-small)) !important;
  height: calc(10 * var(--font-size-3));
}
.body--has-modal {
  overflow: hidden;
}
.portal-reset {
  line-height: inherit;
}
.portal__off-screen {
  position: absolute !important;
  left: -10000px !important;
  top: -10000px !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.icon-button--admin {
  user-select: none;
  margin-right: var(--layout-spacing-unit);
  background-color: var(--button-bgc);
  background-size: 1em;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  border: 0.2rem solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
}
.icon-button--default-cursor,
.icon-button--default-cursor:hover {
  cursor: default !important;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
@media only screen and (max-width: 748px) {
  .sr-only-mobile {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
/*# sourceMappingURL=src/assets/styles/style.css.map */