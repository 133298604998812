
.modal-wrapper
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -999
    opacity: 0
    transition: opacity 0.5s ease;

    &--isVisible
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zindex-2
      background-color: var(--bgc-underlay);
      display: flex
      align-items: center
      justify-content: center
      opacity: 1
      transition: opacity 0.5s ease;

      &> *
        position: relative
        z-index: 1

    &--isSecondLayer
      z-index: $zindex-3

      &> *
        position: relative
        z-index: 1

    &--isVisibleFullscreen
      z-index: $zindex-4
