
.menu-item
  position: relative;
  z-index: 15;
  display: flex;
  align-items: center;
  padding: 1em 0 1em 20px;
  color: inherit;
  text-decoration: none;
  border: 0.2rem solid rgba(0,0,0,0);

  &:hover
    cursor: pointer;

  &:focus
    outline: 0;
    border: 0.2rem solid var(--color-focus);

  &__disabled
    pointer-events: none
    color: var(--font-color-contrast-low)

  &__counter
    position: absolute;
    right: 0;
    margin-right: 4rem;
    display: inline;

  &__arrow
    position: absolute;
    display: inline;
    font-size: inherit;
    width: 1rem;
    height: 1rem;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    transition: color var(--portal-transition-duration);
    &--left
      left: 1.2rem;
    &--right
      right: 0;
      margin-right: 1.2rem;
