.portal-iframe {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.portal-iframe__iframe {
  position: relative;
  border: none;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=src/components/PortalIframe.css.map */