
.icon-button
  position: relative
  width: var(--button-size)
  border-radius: var(--border-radius-circles)
  padding: var(--layout-spacing-unit)
  background-color: transparent

  &--button-style
    background-color: var(--button-bgc)
    border-radius: var(--button-border-radius)
    border: 0.1rem solid transparent
    height: var(--inputfield-size)
    width: @height
    display: flex
    align-items: center
    justify-content: center
