
.portal-error
  position: relative
  display: flex
  flex-direction: column
  margin-left: calc(2 * var(--layout-spacing-unit) + var(--layout-spacing-unit) + 0.2rem)

  @media $mqSmartphone
    margin-left: 1em

  &__title
    margin-bottom: 0
