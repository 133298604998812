.image-upload__canvas {
  position: relative;
  height: 10rem;
  width: 10rem;
  cursor: pointer;
  display: flex;
  background: var(--bgc-checkerboard);
}
.image-upload__canvas img {
  max-height: 10rem;
  margin: auto;
  max-width: 100%;
}
.image-upload__footer {
  margin: var(--layout-spacing-unit) 0;
  display: flex;
}
.image-upload__footer button + button {
  margin-left: var(--layout-spacing-unit);
}
.image-upload__file-input {
  visibility: hidden;
  position: absolute;
}
.image-upload__nofile {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: var(--bgc-inputfield-on-container);
}
.image-upload__nofile span {
  margin: auto;
}
.image-upload__maxFileSize {
  color: var(--font-color-contrast-middle);
  font-size: var(--font-size-5);
  padding-top: var(--layout-spacing-unit-small);
}
.image-upload__standby {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--bgc-inputfield-on-container);
}
.loading-enter-active,
.loading-leave-active {
  transition: opacity 0.5s ease;
}
.loading-enter-from,
.loading-leave-to {
  opacity: 0;
}
/*# sourceMappingURL=src/components/widgets/ImageUploader.css.map */