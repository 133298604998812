.tile-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
}
.tile-add__button {
  margin: 0 0 calc(2 * var(--layout-spacing-unit)) 0;
  min-width: var(--app-tile-side-length);
  width: var(--app-tile-side-length);
  height: var(--app-tile-side-length);
  border-radius: var(--border-radius-apptile);
  border: 0.2rem solid var(--button-bgc);
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
}
.tile-add__button:focus {
  border-color: var(--color-focus);
}
.tile-add__button:focus,
.tile-add__button:hover {
  background-color: transparent;
}
.tile-add__button svg {
  width: 100%;
  height: 100%;
  stroke: var(--button-bgc);
}
.tile-add .portal-tile__name {
  white-space: inherit;
}
/*# sourceMappingURL=src/components/admin/TileAdd.css.map */