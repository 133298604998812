.portal-error {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: calc(2 * var(--layout-spacing-unit) + var(--layout-spacing-unit) + 0.2rem);
}
@media only screen and (max-width: 748px) {
  .portal-error {
    margin-left: 1em;
  }
}
.portal-error__title {
  margin-bottom: 0;
}
/*# sourceMappingURL=src/components/globals/PortalError.css.map */