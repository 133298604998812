body.body--has-selfservice {
  overflow: hidden;
}
.modal-wrapper--selfservice {
  padding: calc(4 * var(--layout-spacing-unit)) 0;
  overflow: auto;
  box-sizing: border-box;
}
.modal-wrapper--selfservice.modal-wrapper--isVisible {
  z-index: 399;
}
.dialog--selfservice {
  margin: auto;
  box-sizing: border-box;
  min-width: min(calc(var(--inputfield-width) + calc(12 * var(--layout-spacing-unit))), 90%);
  min-height: min(200px, 90%);
  max-height: unset;
}
.dialog--selfservice input,
.dialog--selfservice select,
.dialog--selfservice form {
  width: 100%;
}
.dialog--selfservice form main {
  max-height: unset;
  padding: 0;
}
/*# sourceMappingURL=src/views/selfservice/Site.css.map */