
.portal-header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: $zindex-3
  background-color: var(--bgc-content-header)
  color: var(--font-color-contrast-high)
  height: var(--portal-header-height)
  display: flex
  padding: 0 calc(2 * var(--layout-spacing-unit))
  width: 100%
  box-sizing: border-box

  &__tabs
    display: flex;
    flex: 1 1 auto;
    margin-left: calc(5 * var(--layout-spacing-unit));
    width: 100%;
    overflow: hidden
    align-items: center

  &__right
    margin-left: auto
    display: flex;
    align-items: center;

  &__edit-mode-label
    white-space: nowrap
    position: absolute
    top: var(--layout-height-header)
    display: flex
    min-width: 150px
    right: calc(50% - 75px)
    background-color: var(--bgc-content-header)
    align-items: center
    justify-content: center
    padding-left: calc(var(--button-size) / 2)
    transition: top 0.1s ease-in;

    @media only screen and (max-width: 884px) //special mediaquery, since opened sidenav can cause layout irritaions
      top: calc(var(--layout-height-header) - 62%)
      transition: top 0.1s ease-in;

    & button
      margin-left: var(--layout-spacing-unit)

#header-button-copy
    display: none

.portal-header__tabs-overflow
  .portal-header
    &__tabs
      visibility: hidden
  #header-button-copy
      display: flex
