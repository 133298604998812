.notifications {
  position: fixed;
  z-index: 400;
  top: calc(var(--layout-height-header) + 1rem);
  right: var(--layout-spacing-unit);
  width: 90vw;
  max-width: 300px;
  max-height: 100%;
  overflow-y: auto;
  padding-right: calc(3 * var(--layout-spacing-unit));
}
@media only screen and (max-width: 748px) {
  .notifications {
    font-size: var(--font-size-5);
    width: 73vw;
    padding-right: 0;
  }
}
@media only screen and (max-width: 748px) {
  .notifications--in-bar {
    right: 0;
    left: calc(3 * var(--layout-spacing-unit));
    font-size: var(--font-size-5);
  }
}
@media only screen and (max-width: 748px) {
  .notifications--floating {
    right: var(--layout-spacing-unit);
  }
}
.notifications__close-all {
  display: flex;
  justify-content: flex-start;
  margin-bottom: calc(4 * var(--layout-spacing-unit));
}
.notifications__no-notifications {
  font-size: var(--font-size-2);
}
.flyout-wrapper .notifications {
  top: calc(4 * var(--layout-spacing-unit));
  bottom: 0;
}
/*# sourceMappingURL=src/components/notifications/Notifications.css.map */